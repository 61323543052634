/* eslint-disable import/no-anonymous-default-export */
import { 
    OBTENER_CUENTAS,
    CREAR_CUENTAS,
    EDITAR_CUENTAS,
    ELIMINAR_CUENTAS
} from "../../types"

export default (state, action) => {
    switch(action.type){
        case OBTENER_CUENTAS:
            return {
                ...state,
                cuentas: action.payload
            }
        case CREAR_CUENTAS:
            let cuentas_array = state?.cuentas?.length > 0 ? state.cuentas: []
            return {
                ...state,
                cuentas: [...cuentas_array, action.payload]
            }
        case ELIMINAR_CUENTAS:
            return {
                ...state,
                cuentas: action.payload
            }
        case EDITAR_CUENTAS:
            const cuenta_modificada = action.payload;
            const cuentas_modificadas = state?.cuentas?.length > 0 ? state.cuentas.map(item =>{
                if(item?._id === cuenta_modificada?._id){
                    return cuenta_modificada
                }
                return item;
            }): []
            return {
                ...state,
                cuentas: cuentas_modificadas
            }
        default:
            return state
    }
}