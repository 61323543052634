import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  Box,
  TextField,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  Grid2,
} from "@mui/material";
import { AddCircle, EditNote } from "@mui/icons-material";
import renderTabla from "./Tabla";
import { Cuentas as CuentasContext} from '../../context';


const RegistroMensualModal = ({action="create", mesSeleccionado=null, setData = data => console.log(data), setMesSeleccionado = data => console.log(data)}) => {
  const cuentasContext = useContext(CuentasContext);
  const {crearCuenta} = cuentasContext;

  const [open, setOpen] = useState(false); // Estado para abrir/cerrar el modal
  const [formData, setFormData] = useState({
    mes: "",
    cuentas: [],
    suma: 0,
    ingresos: 0,
    restante: 0,
    mixto: false
  });

  useEffect(()=>{
    if(mesSeleccionado){
        setFormData(mesSeleccionado)
    }
  },[mesSeleccionado])
  const [cuenta, setCuenta] = useState({ deuda: "", cantidad: "", dia: "", highlight: false });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setCuenta((prev) => ({ ...prev, [name]: checked }));
    } else if (name.startsWith("cuenta")) {
      setCuenta((prev) => ({ ...prev, [name.split(".")[1]]: value }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const agregarCuenta = () => {
    setFormData((prev) => ({
      ...prev,
      cuentas: [...prev.cuentas, cuenta],
    }));
    setCuenta({ deuda: "", cantidad: "", dia: "", highlight: false });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(action === "create"){
      crearCuenta(formData)
    }else{
      setData(formData)
    }
    setOpen(false); // Cierra el modal al enviar
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      {action === "create" ? (<AddCircle onClick={handleOpen} sx={{position: "absolute", right: 0, top: 0, cursor: "pointer"}}/>): 
      (<EditNote onClick={handleOpen} sx={{position: "absolute", right: 0, top: 0, cursor: "pointer"}}/>)}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "75rem",
            height: "40rem",
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h5" align="center" gutterBottom>
            Registro de Pagos Mensuales
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid2 container spacing={4}>
                <Grid2 size={{xs:4}}>
                    <Typography variant="h6">Detalles</Typography>

                    {/* Campo Mes */}
                    <TextField
                        label="Nombre"
                        name="mes"
                        value={formData.mes}
                        onChange={handleChange}
                        size="small"
                        fullWidth
                        margin="normal"
                        required={action==="create"?true:false}
                    />

                    {/* Campo Ingresos */}
                    <Box sx={{display: "flex"}}>
                      {!formData.mixto ? (<TextField
                        label="Adeudo"
                        name="ingresos"
                        value={formData.ingresos}
                        onChange={handleChange}
                        size="small"
                        fullWidth
                        margin="normal"
                        type="number"
                        required={action==="create"?true:false}
                      />): (<TextField
                          label="Ingresos"
                          name="ingresos"
                          value={formData.ingresos}
                          onChange={handleChange}
                          size="small"
                          fullWidth
                          margin="normal"
                          type="number"
                          required={action==="create"?true:false}
                      />)}
                      
                      <div style={{marginLeft: "20px", display: "flex", alignItems: "center"}}>
                        <input style={{cursor: "pointer"}} checked={formData.mixto ? formData.mixto: false} onClick={e=>{
                          setFormData({
                            ...formData,
                            mixto: e.target.checked
                          })
                        }} id="mixto" type="checkbox"/>
                        <label style={{cursor: "pointer", marginLeft: "6px"}} for="mixto">Mixto</label>
                      </div>
                    </Box>
                    <Typography variant="h6" mt={1}>Cuentas</Typography>
                    {/* Campo Deuda */}
                    <TextField
                        label="Deuda"
                        name="cuenta.deuda"
                        value={cuenta.deuda}
                        onChange={handleChange}
                        size="small"
                        fullWidth
                        margin="normal"
                    />

                    {/* Campo Cantidad */}
                    <TextField
                        label="Cantidad"
                        name="cuenta.cantidad"
                        value={cuenta.cantidad}
                        onChange={handleChange}
                        size="small"
                        fullWidth
                        margin="normal"
                    />

                    {/* Campo Día de Pago */}
                    {/* <TextField
                        label="Fecha de Pago"
                        name="cuenta.dia"
                        value={cuenta.dia}
                        onChange={handleChange}
                        size="small"
                        fullWidth
                        margin="normal"
                    /> */}
                    <TextField
                        type="date"
                        name="cuenta.dia"
                        value={cuenta.dia}
                        onChange={handleChange}
                        size="small"
                        fullWidth
                        margin="normal"
                    />

                    {/* Checkbox Highlight */}
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="highlight"
                                checked={cuenta.highlight}
                                onChange={handleChange}
                                size="small"
                            />
                        }
                        label="Resaltar"
                    />
                    <Button
                        type="button"
                        onClick={agregarCuenta}
                        variant="contained"
                        color="secondary"
                        fullWidth
                        sx={{ mt: 2 }}
                    >
                        Agregar Cuenta
                    </Button>

                    {/* Botón de Enviar */}
                    <Button
                        type="submit"
                        variant="contained"
                        color="success"
                        fullWidth
                        sx={{ mt: 2 }}
                    >
                        {action === "create" ? "Registrar": "Editar"}
                    </Button>
                </Grid2>
                <Grid2 className="scrollBloque" sx={{
                  height: "32rem",
                  overflowY: "scroll"
                }} size={{xs:8}}>
                    {renderTabla(formData, setFormData)}                    
                </Grid2>
            </Grid2>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default RegistroMensualModal;
