import { createContext } from 'react';

export const Auth           = createContext();
export const Cuentas         = createContext();
export const Guests         = createContext();
export const Eventos        = createContext();
export const Alertas        = createContext();
export const Solicitudes    = createContext();
export const Multimedia     = createContext();
export const Secciones      = createContext();
export const Pases          = createContext();
export const Temporal       = createContext();
