import { Delete } from "@mui/icons-material";
import convertirAMoneda from "./formatoMoneda";

// Estilos
const estilosTabla = {
    borderCollapse: "collapse",
    width: "100%",
    marginBottom: "20px",
};

const estilosThTd = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
};

const estilosEncabezado = { backgroundColor: "#f4f4f4", fontWeight: "bold" };
const estilosHighlight = { backgroundColor: "#d8f3dc" };
const estilosSuma = { fontWeight: "bold", backgroundColor: "#f4f4f4" };
const estilosFooter = { fontWeight: "bold", backgroundColor: "#b7e4c7", borderTop: "2px solid #ddd" };

// Renderizar tabla

const SumaValores = (datos) => {
    let total = 0;
    if(datos?.length > 0){
        total = datos.reduce((acumulador, cuenta) => acumulador + parseFloat(cuenta.cantidad), 0)
    }
    return total
}

const calculoRestante = (datos) => {
    const ingresos = datos?.ingresos ? datos.ingresos: 0;
    let total_pagos = datos?.cuentas?.length > 0 ? SumaValores(datos.cuentas): 0;
    if(!datos?.mixto){
        total_pagos = datos?.cuentas?.length > 0 ? SumaValores(datos.cuentas.map(item_cuenta => {
            return {
                ...item_cuenta,
                cantidad: item_cuenta?.pagado ? item_cuenta.cantidad: "0"
            }
        })): 0;
    }
    return ingresos - total_pagos;
}

const renderTabla = (datosMes, setMesSeleccionado, delete_view = true) => {
    return (<table style={estilosTabla}>
        <thead>
            <tr>
            <th style={{ ...estilosThTd, ...estilosEncabezado }}>Deuda</th>
            <th style={{ ...estilosThTd, ...estilosEncabezado }}>Cantidad</th>
            <th style={{ ...estilosThTd, ...estilosEncabezado }}>Fecha de Pago</th>
            {!datosMes.mixto && delete_view ? (<th style={{ ...estilosThTd, ...estilosEncabezado, maxWidth: "10px !important" }}></th>): null}
            {delete_view ?
                (<th style={{ ...estilosThTd, ...estilosEncabezado, maxWidth: "30px !important" }}></th>):
                null
            }
            </tr>
        </thead>
        <tbody>
            {datosMes.cuentas.map((item, index) => (
            <tr key={index} style={item.highlight || item.pagado ? estilosHighlight : undefined}>
                <td style={estilosThTd}>{item.deuda}</td>
                <td style={estilosThTd}>{convertirAMoneda(typeof item.cantidad === "number" ? item.cantidad: parseInt(item.cantidad))}</td>
                <td style={estilosThTd}>{item.dia}</td>
                {delete_view && !datosMes.mixto ? (<td style={{textAlign: "center"}}>
                    <input
                        style={{cursor: "pointer"}}
                        checked={item?.pagado ? item.pagado: false}
                        type="checkbox"
                        onClick={e=>{
                            setMesSeleccionado(old_mes => {
                                return {
                                    ...old_mes,
                                    cuentas: old_mes.cuentas.map(item_old => {
                                        console.log(item, item_old)
                                        if(item_old.deuda === item.deuda){
                                            return {
                                                ...item_old,
                                                pagado: !item.pagado
                                            }
                                        }
                                        return item_old
                                    })
                                }
                            })
                        }}
                    />
                </td>): null}

                {delete_view ? (<td style={{ ...estilosThTd, maxWidth: "20px", textAlign: "center" }}>
                    <Delete onClick={e=>{
                        const cuentas = datosMes.cuentas;
                        let cuentas_eliminada = cuentas.filter((_cuenta, key_cuenta) => key_cuenta !== index)
                        setMesSeleccionado(old_mes => {
                            return {
                                ...old_mes,
                                cuentas: cuentas_eliminada
                            }
                        })   
                    }} sx={{cursor: "pointer"}}/>
                </td>): null}
            </tr>
            ))}
            <tr style={estilosSuma}>
            <td style={estilosThTd}>SUMA</td>
            <td style={estilosThTd}>{convertirAMoneda(SumaValores(datosMes.cuentas))}</td>
            <td style={estilosThTd}></td>
            {delete_view && !datosMes.mixto ?(<td style={{...estilosThTd, maxWidth: "30px !important"}}></td>): null}
            {delete_view ?(<td style={{...estilosThTd, maxWidth: "30px !important"}}></td>): null}
            </tr>
            <tr style={estilosFooter}>
            <td style={estilosThTd}>{datosMes.mixto ? "INGRESOS": "ADEUDO"}</td>
            <td style={estilosThTd}>{convertirAMoneda(typeof datosMes.ingresos === "number" ? datosMes.ingresos: parseInt(datosMes.ingresos))}</td>
            <td style={estilosThTd}></td>
            {delete_view && !datosMes.mixto ?(<td style={{...estilosThTd, maxWidth: "30px !important"}}></td>): null}
            {delete_view ?(<td style={{...estilosThTd, maxWidth: "30px !important"}}></td>): null}
            </tr>
            <tr style={estilosFooter}>
            <td style={estilosThTd}>RESTANTE</td>
            <td style={estilosThTd}>{
                convertirAMoneda(typeof datosMes.restante === "number" ? datosMes.restante: calculoRestante(datosMes))}
            </td>
            <td style={estilosThTd}></td>
            {delete_view && !datosMes.mixto ?(<td style={{...estilosThTd, maxWidth: "30px !important"}}></td>): null}
            {delete_view ?(<td style={{...estilosThTd, maxWidth: "30px !important"}}></td>): null}
            </tr>
        </tbody>
    </table>)
}

export default renderTabla