import React, { useContext, useEffect, useState } from "react";
import RegistroMensualModal from "./Registro";
import renderTabla from "./Tabla";
import {Cuentas as CuentasContext} from '../../context'
import { Box, Grid2, Modal } from "@mui/material";
import { Delete } from "@mui/icons-material";

const PagosMensuales = () => {
  const cuentasContext = useContext(CuentasContext);
  const {cuentas, obtenerCuentas, modificarDatos, eliminarDatos} = cuentasContext;
  // Estado para los datos y mes seleccionado
  useEffect(()=>{ obtenerCuentas() },[])

  useEffect(()=>{
    if(mesSeleccionado){
      console.log(mesSeleccionado)
      let filterSeleccionado = cuentas.filter(cuenta_ => cuenta_?._id === mesSeleccionado?._id)
      if(filterSeleccionado?.length > 0){
        setMesSeleccionado(filterSeleccionado[0])
      }
    }
  },[cuentas])

  const [mesSeleccionado, setMesSeleccionado] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);

  return (
    <div style={{ display: "flex", padding: "10px", fontFamily: "Arial, sans-serif" }}>
      <Modal open={openDelete} onClose={e=>{setOpenDelete(false)}}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "35rem",
            height: "10rem",
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4
          }}
        >
          <p style={{textAlign: "center"}}>¿Desea eliminar la cuenta seleccionada?</p>
          <Grid2 container>
            <Grid2 sx={{display: "grid", justifyContent: "start"}} size={{xs:6}}>
              <p onClick={async e=>{
                const eliminado = await eliminarDatos(mesSeleccionado)
                setOpenDelete(eliminado)
              }} style={{background: "#96cbff", cursor: "pointer", padding: "10px", borderRadius: "5px"}}>Aceptar</p>
            </Grid2>
            <Grid2 sx={{display: "grid", justifyContent: "end"}} size={{xs:6}}>
              <p onClick={e=>setOpenDelete(false)} style={{background: "#96cbff", cursor: "pointer", padding: "10px", borderRadius: "5px"}}>Cancelar</p>
            </Grid2>
          </Grid2>
        </Box>
      </Modal>
      {/* Lista de meses a la izquierda */}
      <div style={{ width: "25%", padding: "10px", borderRight: "2px solid #ddd" }}>
        <div style={{ position: "relative"}}>
            <h3>Cuentas</h3>
            <RegistroMensualModal setData={modificarDatos} setMesSeleccionado={setMesSeleccionado}/>
        </div>
        <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
          {cuentas?.length > 0? cuentas.map((mes, index) => (
            <li
              key={index}
              style={{
                padding: "10px",
                backgroundColor: mesSeleccionado ? mes.mes === mesSeleccionado.mes ? "#d8f3dc" : "#fff": "#fff",
                cursor: "pointer",
              }}
              onClick={() => setMesSeleccionado(mes)}
            >
              <Grid2 container>
                <Grid2 size={{xs: 8}}>{mes.mes}</Grid2>
                <Grid2 size={{xs: 4}} sx={{textAlign: "end"}}>
                  <Delete onClick={e=>{
                    setOpenDelete(true);
                  }}/>
                </Grid2>
              </Grid2>
            </li>
          )): (<p>No hay cuentas</p>)}
        </ul>
      </div>

      {/* Detalles del mes seleccionado */}
      <div style={{ width: "75%" }}>
        {mesSeleccionado ? (<>
            <div style={{position: "relative"}}>
              <h2 style={{ textAlign: "center" }}>{`Pagos ${mesSeleccionado.mes}`}</h2>
              <RegistroMensualModal action="edit" mesSeleccionado={mesSeleccionado} setData={modificarDatos} setMesSeleccionado={setMesSeleccionado}/>
            </div>
            {renderTabla(mesSeleccionado, setMesSeleccionado, false)}
        </>): (<p>No hay cuentas</p>)}
        </div>
      </div>
  );
};

export default PagosMensuales;
