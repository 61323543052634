import React from "react";
import PagosMensuales from "../componentes/cuentas/pagosMensuales";
import MenuEstatico from "../elementos/Menu";

const App = () => {
  return (<MenuEstatico>
    <PagosMensuales/>
  </MenuEstatico>);
};

export default App;
