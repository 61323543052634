import React, { useReducer } from 'react';
import cuentasReducer from './reducer';
import {Cuentas}  from '../';
import clientAxios from '../../config/axios';
import { 
    CREAR_CUENTAS,
    EDITAR_CUENTAS,
    ELIMINAR_CUENTAS,
    OBTENER_CUENTAS
} from '../../types'

const CuentaState = props => {
    const initialState = {
        cuentas: [],
        cuenta: { 
            deuda: "",
            cantidad: "",
            dia: "",
            highlight: false
        }
    }

    const [state, dispatch] = useReducer(cuentasReducer, initialState)

    const obtenerCuentas = async () => {
        try{
            const res = await clientAxios.get('/api/cuentas')
            dispatch({
                type: OBTENER_CUENTAS, 
                payload: res.data.data
            })
        } catch(err){
            console.log(err)
        }
    }

    const modificarDatos = async datos => {
        try{
            const res = await clientAxios.put('/api/cuentas', datos)
            dispatch({
                type: EDITAR_CUENTAS, 
                payload: datos
            })
        } catch(err){
            console.log(err)
        }
    }

    const eliminarDatos = async datos => {
        try{
            const map_cuentas = state.cuentas;
            const res = await clientAxios.post('/api/cuentas/eliminar', datos);
            if(res?.data?.success){
                let cuentas_no_eliminadas = await map_cuentas.filter(item => item._id !== datos._id);
                dispatch({
                    type: ELIMINAR_CUENTAS,
                    payload: cuentas_no_eliminadas
                });
            }
            return false
        } catch(err){
            console.log(err)
            return true
        }
    }

    const crearCuenta = async nueva_cuenta => {
        const res = await clientAxios.post('/api/cuentas', nueva_cuenta)

        if(res?.data?.code !== 200){
            console.log("ERROR AL GUARDAR EVENTO")
            return false
        }

        try{
            dispatch({
                type: CREAR_CUENTAS, 
                payload: res.data.msg
            })

            return true
        } catch(err){
            console.log(err)
            return false
        }
    }
    
    return(
        <Cuentas.Provider value={{
            cuentas: state.cuentas,
            cuenta: state.cuenta,
            crearCuenta,
            eliminarDatos,
            modificarDatos,
            obtenerCuentas
        }}>
            {props.children}
        </Cuentas.Provider>
    )
}

export default CuentaState;
